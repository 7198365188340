//import imgcloudcomputing from '../../../styles/images/cloud-computing.svg';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosClient } from '../../../library/Service/axiosClient';
const imgcloudcomputing = `${process.env.REACT_APP_IMAGE_BASEPATH}/cloud-computing.svg`;
interface CustomOption {
  value: string;
  label: string;
  name: string;
  firstName: string;
  lastName: string;
  profilePictureFilePath?: string;
  schoolName?: string;
  passOutYear?: number;
  programName?: string;
  nation: string;
  achievement : string;
  selectugCollege: number;
  ugCollege: string;
  ugDepartment: string;
  selectpgCollege: number;
  pgCollege: string;
  pgDepartment: string;
  selectjobCompany: number;
  jobCompany: string;
  jobDesignation: string; 
  registeredEmailId: string;
  selectLocation: number;
  selectAchievement: number;
}

interface ValidationErrors {
  alumniName?: string;
  programBatchYear?: string;
  headline?: string;
  testimonialText?: string;
  image?: string;
}

const AddSpeakProfile = () => {
    const { id, code, eid } = useParams();
    const [fuzzySearchText, setFuzzySearchText] = useState('');
    const [alumniList, setAlumniList] = useState([]);
    const [selectedAlumni, setSelectedAlumni] = useState(null);    
    const [selectedUser, setSelectedUser] = useState<CustomOption | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alumniName, setAlumniName] = useState('');
    const [programBatchYear, setProgramBatchYear] = useState('');
    const [headline, setHeadline] = useState('');
    const [testimonialText, setTestimonialText] = useState('');
    const [uploadedImage, setUploadedImage] = useState('');
    const [editData, setEditData] = useState<any>({}); // State to hold the edit data if in edit mode
    const [errors, setErrors] = useState<ValidationErrors>({});

    const isEditing = !!eid;

    useEffect(() => {
        if (isEditing && eid) {  // Ensure eid exists and editing mode is active
            const fetchEditData = async () => {
                try {
                    setIsLoading(true);
                    const widgetResp = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetalumni/${eid}`);
                    const users = widgetResp?.data?.widgetprofileData || [];

                    if (users.length > 0) {
                        const user = users[0];  // Assuming only one user is fetched, otherwise loop through

                        console.log(user, 'editData')
                        // Set the data into editData state
                        setAlumniName(user.user_name || '');
                        setProgramBatchYear(user.user_program || '');
                        setHeadline(user.user_headline || '');
                        setTestimonialText(user.user_testimonial || '');
                        setUploadedImage(user.profile_pic || '');
                    }
                } catch (error) {
                    console.error("Failed to fetch widget data", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchEditData();
        }
    }, [eid, isEditing]);

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;
        const formData = new FormData();

        // Make sure there's at least one file
        if (event.target.files && event.target.files.length > 0) {
            formData.append('image', event.target.files[0]); // Append the first file

            try {
                const response = await axiosClient().post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // Assuming the response returns the new image URL
                const newImageUrl = response.data.link;

                // Update the uploaded image state and the selected user's profile picture
                setUploadedImage(newImageUrl);

            } catch (error) {
                console.error('Error uploading image:', error);
            }
        } else {
            console.log('No file selected');
        }
    };

    const validateForm = (): boolean => {
        let validationErrors: ValidationErrors = {};

        const trimmedAlumniName = alumniName.trim();
        const trimmedProgramBatchYear = programBatchYear.trim();
        const trimmedHeadline = headline.trim();
        const trimmedTestimonialText = testimonialText.trim();

        if (!trimmedAlumniName) {
            validationErrors.alumniName = 'Alumni name is required.';
        } else if (trimmedAlumniName.length > 50) {
            validationErrors.alumniName = 'Alumni name cannot exceed 50 characters.';
        }

        if (!trimmedProgramBatchYear) {
            validationErrors.programBatchYear = 'Program and Batch Year are required.';
        } else if (trimmedProgramBatchYear.length > 100) {
            validationErrors.programBatchYear = 'Program and Batch Year cannot exceed 100 characters.';
        }

        if (!trimmedHeadline) {
            validationErrors.headline = 'Headline is required.';
        } else if (trimmedHeadline.length > 100) {
            validationErrors.headline = 'Headline cannot exceed 100 characters.';
        }

        if (!trimmedTestimonialText) {
            validationErrors.testimonialText = 'Testimonial text is required.';
        } else if (trimmedTestimonialText.length > 750) {
            validationErrors.testimonialText = 'Testimonial text cannot exceed 750 characters.';
        }


        if (!uploadedImage) {
            validationErrors.image = 'Please upload a display image.';
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return;
        }

        const dataToSave = {
            widgetCode: code,
            widgetInstituteListId: id,
            userName: alumniName,
            programName: programBatchYear,
            headLine: headline,
            profilePicture: uploadedImage,
            testimonialText: testimonialText,
            eid: eid || '',
        };

        console.log(dataToSave, 'dataToSave array data')

        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/action/savewidgetlistextra`, dataToSave);
            if (response.status === 200) {
                console.log('Data saved successfully');

                // After successful save/update
                alert('Data has been saved/updated successfully.');

                // Redirect to the desired URL
                window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/widget/configurelist/${code}/${id}`;
            } else {
                console.error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <>
            <div className='bg-gray-F2F4F7 border-radius-6 p-4'>
                <div className="row g-4">
                    <div className="col-md-5">
                        <div className="w-100 h-100 d-flex justify-content-center align-items-start">                  
                            <div className="testimonial_block">
                                <div className="testimonial_img_brife">
                                    <div className="testimonial_img_wrap">
                                        <div className="testimonial_img bg-white">
                                            <img src={uploadedImage || "https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/dummy_male.svg"} width="80" alt=""/>
                                        </div>
                                    </div>
                                    <div className="testimonial_brife">
                                        <h3 className="text-break"><span className="text-break">{alumniName || 'Alumni Name'}</span></h3>
                                        <p className="text-break">{programBatchYear || 'Program & Batch Year'}</p>
                                        <p className="text-break">{headline || 'Headline'}</p>
                                    </div>
                                </div>
                                <div className="testimonial_detail">
                                    <p>{testimonialText || 'Testimonial text will appear here...'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">                  
                        <div className="bg-white border-radius-6 p-4 h-100">
                            <form className="p-2">
                                <div className="row g-3">
                                    <div className="col-md-12">
                                        <div className="row g-2 align-items-center mb-2">
                                            <div className="col">
                                                <label className="form-label m-0 mb-1 font-16 font-600 ">ADD ALUMNI TESTIMONIAL</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <input 
                                            id="alumni-name" 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Enter Alumni Name" 
                                            value={alumniName} 
                                            onChange={(e) => setAlumniName(e.target.value)} 
                                        /> 
                                        {errors.alumniName && <span className="text-danger">{errors.alumniName}</span>}
                                    </div>
                                    <div className="col-12">
                                        <input 
                                            id="alumni-program-batch-year" 
                                            type="text" 
                                            className="form-control " 
                                            placeholder="Enter Alumni Program and Batch Year" 
                                            value={programBatchYear} 
                                            onChange={(e) => setProgramBatchYear(e.target.value)} 
                                        /> 
                                        {errors.programBatchYear && <span className="text-danger">{errors.programBatchYear}</span>}
                                    </div>
                                    <div className="col-12">
                                        <input 
                                            id="alumni-headline" 
                                            type="text" 
                                            className="form-control " 
                                            placeholder="Enter Alumni Headline" 
                                            value={headline} 
                                            onChange={(e) => setHeadline(e.target.value)} 
                                        /> 
                                        {errors.headline && <span className="text-danger">{errors.headline}</span>}
                                    </div>
                                    <div className="col-12">
                                        <textarea 
                                            id="testimonial-text" 
                                            className="form-control h-auto" 
                                            placeholder="Enter the Testimonial text" 
                                            rows={3}
                                            value={testimonialText}
                                            onChange={(e) => setTestimonialText(e.target.value)}
                                        ></textarea>
                                        {errors.testimonialText && <span className="text-danger">{errors.testimonialText}</span>}
                                    </div>
                                    <div className="col-md-12">
                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="cloud-computing">Upload Display Image</label>                   
                                        <div className="upload_section">
                                            <input 
                                                type="file" 
                                                id="cloud-computing" 
                                                onChange={handleImageUpload} 
                                                className=""/>
                                            <div className="row g-2 align-items-center justify-content-center">
                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                        <div className="cloud-img"><img src={imgcloudcomputing} alt=""/></div>
                                                        <div className="cloud-text">Drag and drop file here or <a href="javascript:;" className="text-sky-blue">Browse</a></div>
                                                    </div>                          
                                                </div>                         
                                            </div>
                                        </div>
                                        <span className="text-end font-14 d-block text-dark-blue opacity-7">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                        {errors.image && <span className="text-danger">{errors.image}</span>}
                                    </div>                       
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="action_btns mt-4 max-100per">
                <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5" onClick={handleSave}><span className="px-3">Save</span></button>
            </div>
        </>
    );
};

export default AddSpeakProfile;
