import { useContext, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SessionContext from '../library/Core/SessionContext';
import MenuBuilderServiceAlumni from '../services/menu-builder.service';
import { isSchoolAdmin } from '../library/Core/SmartFunctions';

const MenuBuilder = () => {
    const { sessionState } = useContext(SessionContext);
    const { userTypeCode, userStatusCode, pursuingEducationLevelCode } = sessionState;

    const defaultProductMenu = { pbp: true, cagp: true, ps: true };
    const StudenttestMenu = JSON.parse(sessionStorage.getItem('product-menu-test') || '{}');
    const mergedMenu = { ...defaultProductMenu, ...StudenttestMenu };

    const userId = sessionState.id;
    const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal visibility
    const [openMenu, setOpenMenu] = useState('');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures this effect runs only once

    // TODO: Move the menu config to Database for better maintenance;

    const MyDashBoard = {
        id: 'my-dashboard',
        label: 'MY DASHBOARD',
        children: [
            { id: 'getting-started', label: 'Getting Started', path: '/alumni/onboarding', class: '' },
            { id: 'my-dashboard', label: 'Dashboard', path: '/alumni/dashboard', class: '' },
        ],
    };

    const NextMove = {
        id: 'next-move',
        label: 'Next Move',
        children: [
            { id: 'profile-skills', label: 'Skill Profile', path: `/alumni/${userId}/skills`, class: '' },
            { id: 'study-preference', label: 'Study Preferences', path: `/alumni/${userId}/ug-education-preference`, class: '' },
            { id: 'job-preference', label: 'Job Preferences', path: `/alumni/${userId}/job-preferences`, class: '' },
            { id: 'Achievements', label: 'Achievements', path: `/alumni/${userId}/achievements`, class: '' },
            // { id: 'top-college', label: 'Top Colleges', path: 'top-colleges' },
        ],
    };

    const Connect = {
        id: 'connect',
        label: 'CONNECT',
        children: [
            { id: 'directory', label: 'Directory', path: '/alumni/directory', class: '' },
            { id: 'batch-mates', label: 'Batchmates', path: '/alumni/batch-mates-directory', class: '' },
            { id: 'juniors', label: 'Juniors', path: '/alumni/juniors-directory', class: '' },
            { id: 'Seniors', label: 'Seniors', path: '/alumni/seniors-directory', class: '' },
            { id: 'qna', label: 'QnA Board', path: '/qna-dashboard', class: '' },
            // { id: 'School', label: 'School', path: '/alumni/school-directory', class: '' },
        ],
    };

    const Contribute = {
        id: 'contribute',
        label: 'CONTRIBUTE',
        children: [
            { id: 'memories', label: 'Memories', path: '/memories', class: '' },
            { id: 'guidance', label: 'Guidance Videos', path: '/guidance', class: '' },
            { id: 'job', label: 'Job & Internship', path: '/job-posting', class: '' },
            { id: 'review-profiles', label: 'Review Profile', path: '/alumni-review/peer', class: '' },
        ],
    };

    // Management
    const Manage = {
        id: 'manage',
        label: 'MANAGE',
        children: [
            { id: 'users', label: 'Users', path: '' },
            { id: 'student', label: 'Students', path: '/management/track/student-profiles', class: '' },
            { id: 'alumni', label: 'Alumni', path: '/alumni/directory', class: '' },
            { id: 'lead', label: 'Leads', path: '/lead/managelead', class: '' },
            { id: 'create-user', label: 'Create User', path: '/support/create-new-user', class: '' },

            { id: 'institute_data', label: 'Institute Data', path: '' },
            { id: 'listing', label: 'Programs', path: '/program/listing', class: '' },
        ],
    };

    const Dashboard = {
        id: 'dashboard',
        label: 'DASHBOARD',
        children: [{ id: 'dashboard', label: 'Dashboard', path: '/management/dashboard', class: '' }],
    };

    const ManagementConnect = {
        id: 'management-connect',
        label: 'ENGAGE',
        children: [
            { id: 'student', label: 'Students', path: '/student-messages', class: '' },
            { id: 'alumni', label: 'Alumni', path: '/alumni-messages', class: '' },
            { id: 'broadcast', label: 'Broadcast', path: '/broadcast', class: '' },
            { id: 'invite_to_platform', label: 'Invite to Platform', path: '/alumni-invite/peer', class: '' },
            { id: 'ask_google_review', label: 'Ask Google Review', path: '/broadcast/ask-google-review', class: '' },
        ],
    };

    const ManagementTrackStudents = {
        id: 'management-track-students',
        label: 'STUDENTS',
        children: [
            { id: 'profiles', label: 'Profiles', path: '/management/track/student-profiles', class: '' },
            { id: 'careerPreferences', label: 'Career Preferences', path: '/management/track/student-career-preferences', class: '' },
        ],
    };

    const ManagementTrackAlumni = {
        id: 'management-track-alumni',
        label: 'ALUMNI',
        children: [
            { id: 'profiles', label: 'Profiles', path: '/management/track/alumni-profiles', class: '' },
            { id: 'educationPreferences', label: 'Education Details', path: '/management/track/alumni-education-preferences', class: '' },
            {
                id: 'engagementWithStudents',
                label: 'Engagement with Students',
                path: '/management/track/alumni-engagement-with-students',
                class: '',
            },
            // {
            //     id: 'hightlightsAndStudentsEngaggement',
            //     label: 'Hightlights and Students Engagement',
            //     path: '/management/track/alumni-highlights-with-students-engagement',
            //     class: '',
            // },
        ],
    };

    const ManagementTrack = {
        id: 'management-track',
        label: 'TRACK',
        children: [
            // { id: 'students', label: 'Students', path: '' },
            { id: 'student-profiles', label: 'Student Profiles', path: '/management/track/student-profiles' },
            { id: 'careerPreferences', label: 'Student Career Preferences', path: '/management/track/student-career-preferences' },
            // { id: 'alumni', label: 'Alumni', path: '' },
            { id: 'alumni-profiles', label: 'Alumni Profiles', path: '/management/track/alumni-profiles' },
            { id: 'educationPreferences', label: 'Alumni Education Details', path: '/management/track/alumni-education-preferences' },
            { id: 'engagementWithStudents', label: 'Engagement with Students', path: '/management/track/alumni-engagement-with-students' },
            // {
            //     id: 'hightlightsAndStudentsEngaggement',
            //     label: 'Hightlights and Students Engagement',
            //     path: '/management/track/alumni-highlights-with-students-engagement',
            // },
        ],
    };

    const ManagementResources = {
        id: 'management-resources',
        label: 'TOOLKIT',
        children: [
            { id: 'guidance', label: 'Guidance Videos', path: '/guidance', class: '' },
            { id: 'widget', label: 'Widget', path: '/widget/listing', class: '' },
            { id: 'Email Template', label: 'Email Template', path: '/broadcast/email-templates', class: '' },
        ]
    };

    const ManagementMeetingsAndEvents = {
        id: 'management-meetings-events',
        label: 'MEETINGS & EVENTS',
        children: [
            { id: 'upcoming-events', label: 'Upcoming Events', path: '/events/upcoming', class: '' },
            { id: 'past-events', label: 'Past Events', path: '/events/past', class: '' },
        ],
    };

    // Student Menu
    const MyJourny = {
        id: 'myjourny',
        label: 'My journey',
        // children: [],
    };

    const RESEARCH = {
        id: 'research',
        label: 'RESEARCH',
        children: [
            { id: 'alumni-guidance', label: 'Alumni Guidance', path: '/guidance', class: '', target: '_blank' },
            { id: 'recommended-college', label: 'Recommended Colleges', path: '#', class: 'gray-effect' },
            { id: 'top-college', label: 'Top Colleges', path: '/top-colleges', class: '' },
            //{ id: 'top-college', label: 'Top Colleges', path: '#', class: 'gray-effect' },
            { id: 'careers', label: 'Careers', path: '#', class: 'gray-effect' },
            { id: 'top-scholarships', label: 'Scholarships', path: '#', class: 'gray-effect' },
            { id: 'admission-tests', label: 'Admission Tests', path: '#', class: 'gray-effect' },
        ],
    };

    const StudentConnect = {
        id: 'student-connect',
        label: 'CONNECT',
        children: [
            { id: 'alumni-search', label: 'Alumni', path: '/alumni/directory', class: '' },
            { id: 'counsellor', label: 'Counsellor', path: '#', class: 'gray-effect' },
            { id: 'university', label: 'University', path: '#', class: 'gray-effect' },
            { id: 'qna', label: 'QnA Board', path: '/qna-dashboard', class: '' },
            // { id: 'bas', label: 'Book a session', path: '#', class: 'gray-effect' },
        ],
    };

    const ManageAdmin = {
        id: 'manage-admin',
        label: 'Manage',
        children: [
            { id: 'institute_data', label: 'Institute Data', path: '' },
            { id: 'listing', label: 'Institute Contacts', path: '/institute/listing', class: '' },
            { id: 'Event', label: 'Events', path: '/event/manageevent', class: '' },
        ],
    };

    const ReportsAdmin = {
        id: 'reports-admin',
        label: 'Reports',
        children: [
            { id: 'all-alumni-report', label: 'All Alumni Profiles', path: '/support/all-alumni-report', class: '' },
            { id: 'profiles', label: 'All Student Profiles', path: '/support/student-profile-report', class: '' },
        ],
    };

    const SupportAdmin = {
        id: 'support-admin',
        label: 'Alumni Deep Data',
        children: [
            { id: 'create-new-user', label: 'Create New Student/Alumni', path: '/support/create-new-user', class: '' },
            {
                id: 'non-standard-colleges-report',
                label: 'Free Text College Profiles',
                path: '/support/non-standard-colleges-report',
                class: '',
            },
            { id: 'non-standard-exams-report', label: 'Free Text Exam Profiles', path: '/support/non-standard-exams-report', class: '' },
            { id: 'domain-data', label: 'Domain Category Report', path: '/domaincategory/list', class: '' },

            // { id: 'benefits-report', label: 'Alumni Benefits Report', path: '/support/benefits-report', class: '' },
            // { id: 'listing', label: 'Institute Contacts', path: '/institute/listing', class: '' },
            // { id: 'Event', label: 'Event Management', path: '/event/manageevent', class: '' },
        ],
    };

    const Events = {
        id: 'school-events',
        label: 'Events',
        children: [
            { id: 'upcoming-events', label: 'Upcoming Events', path: '/events/upcoming', class: '' },
            { id: 'past-events', label: 'Past Events', path: '/events/past', class: '' },
        ],
    };

    const MeetingAndEvents = {
        id: 'meetings-events',
        label: 'Meetings & Events',
        children: [
            { id: 'upcoming-events', label: 'Upcoming Events', path: '/events/upcoming', class: '' },
            { id: 'past-events', label: 'Past Events', path: '/events/past', class: '' },
        ],
    };

    const Products = {
        id: 'products',
        label: 'Products',
        children: [
            // {
            //     id: 'cagp',
            //     label: 'College Application Guidance Program',
            //     path: 'https://products.univariety.com/product/college-application-guidance-program-gem-go-the-extra-mile-premium/',
            // },
            // {
            //     id: 'mips',
            //     label: 'Masterclass in Public Speaking ',
            //     path: 'https://products.univariety.com/product/masterclass-in-public-speaking/',
            // },
            {
                id: 'pbp',
                label: 'Profile Building program',
                path: '/psychometric_test/careerGuide/Commerece',
                class: '',
            },
            {
                id: 'cagp',
                label: 'College Application Guidance Program',
                path: 'https://products.univariety.com/product/college-application-guidance-program-gem-go-the-extra-mile-premium/',
                class: '',
            },
            {
                id: 'ps',
                label: 'ProSpeak (Public Speaking)',
                path: 'https://products.univariety.com/product/prospeak/',
                class: '',
            },
            {
                id: 'ict',
                label: 'Ideal Career Test',
                path: '/psychometric_test/careerGuide/Idealcareertest',
                class: '',
            },
            {
                id: 'ptt',
                label: 'Personality Type Test',
                path: '/psychometric_test/careerGuide/PersonalityTest',
                class: '',
            },
            {
                id: 'lst',
                label: 'Learning Style Test',
                path: '/psychometric_test/careerGuide/Learningstyletest',
                class: '',
            },
            {
                id: 'mit',
                label: 'Multiple Intelligence Test',
                path: '/psychometric_test/careerGuide/MultipleIntelligenceTest',
                class: '',
            },
            {
                id: 'sst',
                label: 'Stream Selection Test',
                path: '/psychometric_test/careerGuide/StreamSelectorTest',
                class: '',
            },
            {
                id: 'eat',
                label: 'Engineering Assessment Test',
                path: '/psychometric_test/careerGuide/Engineering',
                class: '',
            },
            {
                id: 'hat',
                label: 'Humanities Assessment Test',
                path: '/psychometric_test/careerGuide/Humanities',
                class: '',
            },
        ].filter((item) => mergedMenu[item.id]),
    };

    const SuperAdminDashboard = {
        id: 'dashboard',
        label: 'DASHBOARD',
        children: [
            // { id: 'dashboard', label: 'Dashboard', path: '/admin/dashboard', class: '' }
            { id: 'all-school-summary', label: 'All Institute Summary', path: '/support/all-school-summary', class: '' },
            // { id: 'alumni-student-matching', label: 'Alumni Match for Students', path: '/support/alumni-student-match', class: '' },
        ],
    };

    const AlumniEngagement = {
        id: 'alumni-engagement',
        label: 'Customer Success',
        children: [
            {
                id: 'hightlightsAndStudentsEngaggement',
                label: 'Teleport',
                path: '/admin/track/alumni-highlights-with-students-engagement',
            },
            {
                id: 'schoolApiKey',
                label: 'School Api Key',
                path: '/schoolApiKey/api-key/',
            },
            // { id: 'alumni-school', label: 'School Wise Alumni Matches', path: '/support/alumni-school', class: '' },
            { id: 'alumni-student-matching', label: 'Alumni Match for Students', path: '/support/alumni-student-match', class: '' },
        ],
    };

    const SuperAdminReports = {
        id: 'super-admin-reports',
        label: 'Reports',
        children: [
            {
                id: 'hightlightsAndStudentsEngaggement',
                label: 'Alumni Engagement',
                path: '/admin/track/alumni-highlights-with-students-engagement',
            },
        ],
    };

    const navToggleRef = useRef(null);

    const handleClick = () => {
        // Trigger the click event
        if (windowWidth < 992) {
            // Perform your click event actions here
            navToggleRef.current?.click();
        }
    };

    // const SchoolManagement = [
    //     Manage,
    //     ManagementTrackStudents,
    //     ManagementTrackAlumni,
    //     ManagementConnect,
    //     ManagementMeetingsAndEvents,
    //     ManagementResources,
    // ];

    let menuConfig;

    let alumniMenu = [MyDashBoard, NextMove, Connect, Contribute, MeetingAndEvents];
    let studentMenu = [MyJourny, RESEARCH, StudentConnect, Events, Products];

    switch (userTypeCode) {
        case 'ALUMNI':
        case 'STUDENT':
            // switch (pursuingEducationLevelCode) {
            //     case 'K12':
            //         menuConfig = MenuBuilderServiceAlumni.buildStudentMenu(
            //             [MyJourny, RESEARCH, StudentConnect, Events, Products],
            //             sessionState
            //         );
            //         break;
            //     case 'UG':
            //     case 'PG':
            //         menuConfig = MenuBuilderServiceAlumni.buildAlumniMenu(
            //             [MyDashBoard, NextMove, Connect, Contribute, MeetingAndEvents],
            //             sessionState
            //         );
            //         break;
            //     default:
            //         let schoolArr = ['1','2','3','4','5','6','7','8','9','10','11','12'];
            //         if ( schoolArr.includes(pursuingEducationLevelCode) ) {
            //             menuConfig = MenuBuilderServiceAlumni.buildStudentMenu(
            //                 [MyJourny, RESEARCH, StudentConnect, Events, Products],
            //                 sessionState
            //             );
            //             break;
            //         }
            //         throw new Error('Invalid pursuing education level for student');
            // }
            // if ( sessionState.instituteTypeCode == "UNIVERSITY" || (userTypeCode == "ALUMNI" && sessionState.instituteTypeCode == "SCHOOL" ) ) {
            //     menuConfig = MenuBuilderServiceAlumni.buildAlumniMenu(
            //         alumniMenu,
            //         sessionState
            //     );
            // } else {
            //     menuConfig = MenuBuilderServiceAlumni.buildAlumniMenu(
            //         studentMenu,
            //         sessionState
            //     );
            // }
            console.log(sessionState, ' ========= sessionState ======================----------');
            if (sessionState.menuType == 'ALUMNI') {
                menuConfig = MenuBuilderServiceAlumni.buildAlumniMenu(alumniMenu, sessionState);
            } else {
                menuConfig = MenuBuilderServiceAlumni.buildAlumniMenu(studentMenu, sessionState);
            }
            break;

        // menuConfig = MenuBuilderServiceAlumni.buildAlumniMenu(
        //     [MyDashBoard, NextMove, Connect, Contribute, MeetingAndEvents],
        //     sessionState
        // );
        // break;
        // case 'STUDENT':
        //     menuConfig = MenuBuilderServiceAlumni.buildStudentMenu([MyJourny, RESEARCH, StudentConnect, Events, Products], sessionState);
        //     break;
        case 'INSTITUTE_ADMIN':
            menuConfig = [
                Dashboard,
                Manage,
                ManagementConnect,
                ManagementTrack,
                ManagementMeetingsAndEvents, // before this track will come
                ManagementResources,
                // ManagementTrackAlumni,
                // ManagementTrackStudents,
            ];
            break;
        // case 'SUPPORT':
        //     menuConfig = [Dashboard, Connect, SupportAdmin];
        //     break;
        case 'SUPER_ADMIN':
            menuConfig = [SuperAdminDashboard, ManageAdmin, AlumniEngagement, ReportsAdmin, SupportAdmin];
            break;
        default:
            throw new Error('Invalid user type');
    }

    if (!isSchoolAdmin() && sessionStorage.getItem('is-impersonated-session') === 'YES') {
        // menuConfig = [SuperAdminDashboard, AlumniEngagement, SupportAdmin];
        menuConfig = [SuperAdminDashboard, ManageAdmin, AlumniEngagement, ReportsAdmin, SupportAdmin];
    }

    const handleMenuToggle = (menuId) => {
        if (menuId != openMenu) {
            setOpenMenu(menuId);
        } else {
            setOpenMenu('');
        }
    };

    const renderMenuItem = (item) => (
        <li key={item.id} className="nav-item dropdown" data-aos="fade-down">
            <Link
                to="#"
                className="nav-link dropdown-toggle text-white"
                role="button"
                // onClick={!item?.children?.length > 0 ? () => setIsModalOpen(true) : ''} // Open the modal on click
                onClick={!item?.children?.length > 0 ? () => setIsModalOpen(true) : () => handleMenuToggle(item.id)} // Open the modal on click
                // data-bs-toggle="collapse"
                // data-bs-target={`#nav-item-${item.id}`}
                aria-expanded={openMenu ? 'true' : 'false'}
                // aria-expanded="false"
            >
                {item.label}
            </Link>
            {item?.children?.length > 0 && ( // Render only if there are children
                <ul className={`collapse ${openMenu == item.id ? 'show' : ''}`} id={`nav-item-${item.id}`}>
                    {item.children.map((child) => (
                        <li key={child.id} data-aos="fade-right">
                            {child.path == '#' ? (
                                <Link
                                    to="#"
                                    className="dropdown-item gray-effect"
                                    onClick={() => setIsModalOpen(true)} // Open the modal on click
                                >
                                    {child.label}
                                </Link>
                            ) : child.path.match(/^http(s)?:\/\//) ? (
                                <a
                                    href={child.path}
                                    className="dropdown-item"
                                    onClick={handleClick}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {child.label}
                                </a>
                            ) : child.path == '' ? (
                                <div className="menu-label-child">{child.label}</div>
                            ) : (
                                <Link target={child?.target} to={child.path} className="dropdown-item" onClick={handleClick}>
                                    {child.label}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );

    return (
        <>
            {userTypeCode === 'ALUMNI' ? (
                (userStatusCode === 'ACTIVE' || sessionStorage.getItem('is-impersonated-session') === 'YES') && (
                    <nav className="navbar navbar-expand-lg navbar-light custom-nav offcanvas offcanvas-start" id="offcanvasMobile">
                        <button
                            className="mobileonly btn-offcanvas d-none"
                            ref={navToggleRef}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasMobile"
                            aria-controls="offcanvasMobile">
                            <span className="navbar-toggler-icon">
                                <b>#</b>
                            </span>
                        </button>

                        <ul className="navbar-nav mx-auto d-flex justify-content-around" id="custom-nav">
                            {menuConfig.map((item) => renderMenuItem(item))}
                        </ul>
                    </nav>
                )
            ) : (
                <nav className="navbar navbar-expand-lg navbar-light custom-nav offcanvas offcanvas-start" id="offcanvasMobile">
                    <button
                        className="mobileonly btn-offcanvas d-none"
                        ref={navToggleRef}
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasMobile"
                        aria-controls="offcanvasMobile">
                        <span className="navbar-toggler-icon">
                            <b>#</b>
                        </span>
                    </button>
                    <ul className="navbar-nav mx-auto d-flex justify-content-around" id="custom-nav">
                        {menuConfig.map((item) => renderMenuItem(item))}
                    </ul>
                </nav>
            )}

            {/* Modal component */}
            {isModalOpen && (
                <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    This feature is not a part of your subscription. Please contact your school admin if you need access to
                                    these features.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => setIsModalOpen(false)}
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MenuBuilder;
