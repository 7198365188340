import { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SmartContext } from '../../../library/Core/SmartContext';

import { Tooltip } from 'bootstrap';
import { getStudentImage } from '../../../common/styles/Images';
import { formatFullName, getWhiteLabelUrl } from '../../../library/Core/SmartFunctions';
import CardBottom from './CardBottom';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import SessionContext from '../../../library/Core/SessionContext';

const AlumniCardGridControl = () => {
    const { state } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const students = state.data.students;
    const [displayedStudents, setDisplayedStudents] = useState(students?.slice(0, 20));
    const [hasMore, setHasMore] = useState(displayedStudents.length < students.length);

    // const getUniversities = (students: any) => {
    //     const universityIds: any[] = [];
    //     students?.forEach((student: any) => {
    //         if (!universityIds.includes(student.universityId) && student.universityId !== null) {
    //             universityIds.push({ universityId: student.universityId });
    //         }
    //     });

    //     return universityIds;
    // };

    // const universities = getCollegeInfo(getUniversities(students), state);

    const fetchMoreStudents = () => {
        if (displayedStudents.length >= students.length) {
            setHasMore(false);
            return;
        }

        const startIndex = displayedStudents.length;
        const endIndex = startIndex + 20;
        const newDisplayedStudents = students.slice(startIndex, endIndex);
        setDisplayedStudents((prevStudents: any) => [...prevStudents, ...newDisplayedStudents]);
    };

    // useEffect(() => {
    //     var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    //     var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //         return new Tooltip(tooltipTriggerEl);
    //     });
    // }, []);

    // console.log('State');
    // console.log(state.data);

    useEffect(() => {
        // Initialize tooltips when the component mounts
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('.btn-white-tooltip'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
                customClass: 'tooltip-white'
            });
        });
    
        return () => {
            tooltipList.forEach((tooltip) => tooltip.dispose());
        };
    }, [displayedStudents,state?.data?.cardViewMode]);

    useEffect(() => {
        setDisplayedStudents(students?.slice(0, 20));
        if (students?.length > 20) {
            setHasMore(true);
        }
    }, [students]);

    const noRecordsFound = () => (
        <div className="row g-2 g-md-4 alumni-showcase" id="FilterData">
            <div className="no_listed_items_blk">
                <div className="mt-0">
                    <div className="max-470 mx-auto">
                        <img
                            className="mt-2 mb-4 float_horizontal_animate"
                            src={`${process.env.REACT_APP_IMAGE_BASEPATH}/alumni_directory_nodata.svg`}
                        />
                        <p className="font-18 font-400 mb-4 text-center">
                            Looks like no alumni matching your search criteria has an active account yet!
                        </p>
                        {sessionStorage.getItem('user-type-code') == 'ALUMNI' && (
                            <div>
                                <p className="font-18 font-400 mb-4 text-center">
                                    Don’t worry! You can send invites to them and have them activate their profile on the network.
                                </p>
                                <div className="xscroll">
                                    <div className="d-block text-center mx-5 px-5">
                                        <a href="/alumni-invite/peer" className="btn-green height-40 text-white me-2">
                                            <span>Send Invite !</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    // const studentProfileLink = (uuid: string) => {
    //     const base_path=getWhiteLabelUrl(sessionState)
    //     const link = `${base_path}alumni/${uuid}/profile`;
    //     return link;
    // };

    const studentProfileLink = (uuid: string) => {
        const link = `/alumni/${uuid}/profile`;
        return link;
    };

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/send-message/${id}`);
    };

    const handleNoImg = () => {
        return `${process.env.REACT_APP_IMAGE_BASEPATH}/no_university_img.svg`;
    };
    
    const geAlumniName = (student: any) => {
        return (
            `<div class="alumni_univarsity_brife">
                    <div class="alumni_univarsity_subject">
                        <span class="text-black">${formatFullName(student.firstName, student.lastName)}</span>
                    </div>
                </div>`
        );
    };

    return (
        <section className="alumni_directory_details bg-white container-fluid">
            <div className="max-1140 pt-4 pb-5 mx-auto">
                <div className="row mb-3">
                    <div className="col">
                        {/* <h3 className='font-18 font-500 mt-3 mb-0'>Alumni Directory Name</h3>  this needs to be incorporated going forword */}
                    </div>
                    <div className="col-auto">
                        <div className="alumni_count" id="UsersCounts">
                            {state?.data?.students?.length ?? 0} Alumni Found
                        </div>
                    </div>
                </div>

                {
                    <InfiniteScroll dataLength={displayedStudents.length} next={fetchMoreStudents} hasMore={hasMore} loader={''}>
                        <div className="overflow-hidden">
                            <div className="row g-2 g-md-4 alumni-showcase">
                                {displayedStudents?.length > 0 &&
                                    displayedStudents?.map((student: any) => {
                                        // const university = universities.find(
                                        //     (university: any) => university.universityId === student.universityId
                                        // );

                                        return (
                                            // <AlumniGridWithCardsControl alumni={student} />
                                            <div key={student.uuid+' '+student.programName} className="col-lg-4 col-md-6 col-sm-6 Dusers">
                                                <div className="alumni-bucket">
                                                    <div className="alumni_class_link">
                                                        <div className="alumni_name_detail">
                                                            <div className="alumni_img">
                                                                <a
                                                                    className="alumni_img_link"
                                                                    target="_blank"
                                                                    href={studentProfileLink(student.uuid)}
                                                                    rel="noreferrer">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={getStudentImage(student)}
                                                                        alt={`${formatFullName(student.firstName, student.lastName)}`}
                                                                    />
                                                                </a>
                                                            </div>
                                                            <div className="alumni_name_brife">
                                                                <h3>
                                                                    {/* <a
                                                                        className="alumni_name_link"
                                                                        // target="_blank"
                                                                        data-bs-toggle="tooltip"
                                                                        href={studentProfileLink(student.uuid)}
                                                                        title={`${formatFullName(student.firstName, student.lastName)}`}
                                                                        rel="noreferrer">
                                                                        {`${formatFullName(student.firstName, student.lastName)}`}
                                                                    </a> */}
                                                                    <a
                                                                        className="btn-white-tooltip "
                                                                        href={studentProfileLink(student.uuid)}
                                                                        target="_blank"
                                                                        // data-bs-toggle="tooltip"
                                                                        // data-bs-html="true"
                                                                        // title=""
                                                                        // data-bs-original-title={geAlumniName(student)}
                                                                        >
                                                                       {`${formatFullName(student.firstName, student.lastName)}`}
                                                                    </a>

                                                                </h3>
                                                                <span className="alumni_campus">
                                                                    {student?.programName}, {student?.passOutYear}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="send_link">
                                                            <a
                                                                role="button"
                                                                onClick={(event) => handleSendMessage(event, student.uuid)}
                                                                className="btn-send">
                                                                <i className="icon-alumni-send"></i>
                                                                <span>Send</span>
                                                            </a>
                                                            {student.badges === 1 && (
                                                                <span className="conversation-badge">
                                                                    <i className="icon-multiple-users"></i>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <CardBottom alumni={student} state={state} />
                                                    {/* <div className="alumni_univarsity_details">
                                                        <div className="alumni_univarsity_details_inner_cover">
                                                            <div className="alumni_univarsity_logo">
                                                                <img
                                                                    src={getUniversityImage(student?.collegeIconFilePath)}
                                                                    // alt={student?.universityName}
                                                                    alt={"No ..Image"}
                                                                />
                                                            </div>
                                                            <div className="alumni_univarsity_brife">
                                                                <div className="alumni_education">Course</div>
                                                                <div className="alumni_univarsity_name" data-bs-toggle="tooltip" title={student?.universityName}>
                                                                    {student?.universityName}
                                                                    {student?.isPrestigiousCollege == 1 && (
                                                                        <span className="icon-subtraction"></span>
                                                                    )}
                                                                </div>
                                                                <div className="alumni_univarsity_subject">
                                                                    <i></i>
                                                                    <span>{getFieldOfStudyName(student.fieldOfStudyCode, state)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </InfiniteScroll>
                }

                {!state?.flags.isDataLoading && students.length === 0 && noRecordsFound()}
            </div>
        </section>
    );
};

export default AlumniCardGridControl;
