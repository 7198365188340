import Swal from 'sweetalert2';
import logger from '../../library/Core/logger';
import { axiosClient } from '../../library/Service/axiosClient';
import { isEmpty } from '../../library/Core/SmartFunctions';

class WidgetServiceList {
    static async pageLoader(params: any) {
        console.log(params);
        const state: any = {};
        const { id, pageName, code } = params;
        const allowed_codes: any = ['guiding_alumni_mentors', 'notable_alumni_mentors', 'alumni-speak', 'entrepreneur_alumni', 'eclectic_career_alumni', 'spotlight']; // need to fetch the allowed codes from widget_master table.
        const isEditing = !!id;
        console.log(isEditing, 'isEditing')
        let internal: any = {};
        let data: any = {};
        let wgData: any = {};
        internal.widget_code = code;

        if (isEditing) {
            try {
                const widgetList = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetinfo/${id}`);
                console.log(widgetList, 'widgetList')
                const widgetListData = widgetList.data;
                internal.guidinglist = widgetListData;

                data = {...data, countData: widgetListData, widgetData: {...data.widgetData, heading:""} }
            } catch (error:any) {
                console.error('Error fetching or processing widget data:', error.message);
            }
        }

        try {
            if (!allowed_codes.includes(code)) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "You're on the wrong page, Please click on Configure/Update button in the widget listing page.",
                    footer: '<a href="/widget/listing">Go Back to the listing page?</a>'
                });
                throw new Error("Err: Widget Code is not recognized..!");
            }
        } catch (error) {
            console.error('Error fetching program data:', error);
            return false;
        }

        // let response: any;
        // let countsData: any;

        switch (code) {
            case 'guiding_alumni_mentors':
                console.log('data');
                break;
            case 'notable_alumni_mentors':
                console.log('data');
                break;
            case 'alumni-speak':
                console.log('data');
                break;
            case 'eclectic_career_alumni':
                console.log('data');
                break;
            case 'entrepreneur_alumni':
                console.log('data');
                break;
            default:
                console.log('No matching widget code found.');
                break;
        }


        try {
            //const config = await this.getPageConfig(code);
            state.formConfig = '';
            state.routeInfo = { id, pageName };
            state.data = data;
            state.internal = internal;
            return Promise.resolve(state);
        } catch (err) {
            logger.log(`Error in loading data for ${pageName} and the error is:`);
            console.error(err);
            return Promise.reject(err);
        }

        

        //return true; // Indicate successful execution
    }

    // static async getPageConfig(code: any) {
    //     // return Promise.resolve(eval(`pageConfig_${code}`));
    //     try {
    //         const config = await import(`./pageConfig_${code}.json`);
    //         return Promise.resolve(config.default);
    //     } catch (error) {
    //         Swal.fire({
    //             icon: "error",
    //             title: "Oops...",
    //             text: "This Widget Is Under Development",
    //             footer: '<a href="/widget/listing">Go Back to listing page?</a>'
    //           });
    //         return Promise.reject(new Error(`No configuration found for code: ${code}`));
    //     }

    // }
}

export default WidgetServiceList;
