import { activateUserSession, isFormValid } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { getDomainElementsForGivenYearRange } from '../../../../library/Service/domainService';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';
import axios from 'axios';

export const onStartOrEndYearChange = (props: ControlOnChangeArguments) => {
    const errorMessages = [] as any[];

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    if (props.control.id === 'fromDate') {
        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: 'alumniBreak.toDate',
                value: parseInt(props.value) < currentYear ? parseInt(props.value) + 1 : null,
            },
        });

        props.dispatch({
            type: 'SET_DOMAIN',
            payload: {
                key: 'BREAK_END_YEAR_CODE',
                value: getDomainElementsForGivenYearRange('BREAK_END_YEAR_CODE', parseInt(props.value), currentYear),
            },
        });
    }

    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: 'underGradCollegeJoined.ugJoinedStartAndEndYear', errorMessages },
    // });
};

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(state.data['profile']);

    // const errorKeysToIgnore = getErrorsToIgnore(state);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    if (!isImpersonatedSession && !isFormValid(state, dispatch /*, errorKeysToIgnore*/)) {
        alert('Please correct the form errors!');
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            selectedInstitute: state?.data?.selectedInstitute,
            userInstitutes: state?.data?.userInstitutes,
            profile: {
                ...state?.data?.profile,
                contactEmailId: state?.data?.profile.contactEmailId,
                contactMobileNumber: state?.data?.profile.contactMobileNumber,
                contactMobileCountryCode: state?.data?.profile.contactMobileCountryCode,
            },
            alumniBreak: state?.data?.alumniBreak,
        })
    ).then((response) => {
        if (response?.status === 200) {
            // Call the API here to update the status as ACTIVE & COMPLETE.
            // activateUserSession(sessionState, sessionDispatch).then(() => {
            //     state.actions['REDIRECT'](`/alumni/${sessionState.id}/profile`);
            // });

            // Send event to Moengage job outcome
            sendMoEngageEvent(state?.data);

            navigateToNextStep(AlumniProfileEditScreens.BREAK, state, dispatch, sessionState, sessionDispatch);
        }
    });
};

const getErrorsToIgnore = (state: State) => {
    if (state.data.profile.passOutYear > new Date().getFullYear() - 2 && state.data.profile.didYouTakeGapAfter12 === true)
        return ['profile.educationOrEmploymentCurrentStatusCode'];
};


const sendMoEngageEvent = async (user: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-student-took-a-break`, {
            userInfo: user,
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
};