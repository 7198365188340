import Swal from 'sweetalert2';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';

class AddGuidingAlumniList {
    static async pageLoader(params: any) {
        // Log the input parameters for debugging
        console.log(params);

        // Extract parameters
        const { id, eid, code } = params;

        console.log(code, 'code')
        console.log(id, 'id')
        console.log(eid, 'eid')
        const allowed_codes: string[] = ['guiding_alumni_mentors','notable_alumni_mentors','alumni-speak', 'entrepreneur_alumni', 'eclectic_career_alumni', 'spotlight']; // Fetch the allowed codes from widget_master table
        const isEditing = !!eid;
        console.log(isEditing, 'isEditing');

        // Initialize internal and data objects
        let internal: any = {};
        let data: any = {};
        internal.widget_code = code;

        try {
            // Check if the widget code is allowed
            if (!allowed_codes.includes(code)) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "You're on the wrong page, Please click on Configure/Update button in the widget listing page.",
                    footer: '<a href="/widget/listing">Go Back to the listing page?</a>'
                });
                throw new Error("Err: Widget Code is not recognized..!");
            }
        } catch (error) {
            console.error('Error validating widget code:', error);
            return false;
        }

        // Handle different widget codes
        switch (code) {
            case 'guiding_alumni_mentors':
                console.log('Handling guiding_alumni_mentors case.');
                break;
            case 'notable_alumni_mentors':
                console.log('Handling notable_alumni_mentors case.');
                break;
            case 'alumni-speak':
                console.log('Handling alumni-speak case.');
                break;
            case 'eclectic_career_alumni':
                console.log('Handling eclectic_career_alumni case.');
                break;
            case 'entrepreneur_alumni':
                console.log('Handling entrepreneur_alumni case.');
                break;
            default:
                console.log('No matching widget code found.');
                break;
        }

        // Indicate successful execution
        return true;
    }
}

export default AddGuidingAlumniList;
